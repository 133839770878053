<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        style="height: 30px"
        elevation="0"
        block
        v-on="on"
        :disabled="$store.getters['auth/getSetting']('pm_stripe_id') == 0"
        @click="fetch"
        >{{ $t("accounts.configuration") }}</v-btn
      >
    </template>
    <v-card>
      <v-card-title>{{ $tc("discount", 2) }}</v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row align="center">
          <v-col cols="10" md="2" class="py-0">
            <v-text-field
              outlined
              dense
              v-model="filters.search"
              prepend-inner-icon="mdi-magnify"
              class="ml-auto filters search"
              :label="$t('search')"
              hide-details
              single-line
              @input="searchFilter"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            :order="$vuetify.breakpoint.xs ? 1 : null"
            class="py-0"
          >
            <v-select
              :label="$t('appointments.state')"
              outlined
              dense
              v-model="filters.state"
              class="ml-auto filters"
              hide-details
              single-line
              :items="states"
              clearable
              @input="searchFilter"
            />
          </v-col>
          <v-spacer />
          <v-dialog v-model="dialogForm" max-width="500">
            <template v-slot:activator="{}">
              <v-btn
                icon
                elevation="0"
                style="z-index: 10"
                class="mr-1 mr-md-0"
                @click="openForm()"
              >
                <v-icon large>mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>{{
                $t("new", { name: $tc("discount", 1) })
              }}</v-card-title>
              <div class="close">
                <v-btn icon @click="dialogForm = false" small>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <form
                action
                data-vv-scope="discount-form"
                @submit.prevent="validateForm('discount-form')"
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <label v-text="$t('discounts.code')"></label>
                      <v-text-field
                        outlined
                        hide-details
                        dense
                        v-model="discount.code"
                        data-vv-validate-on="blur"
                        v-validate="'required'"
                        :error-messages="errors.collect('discount-form.code')"
                        data-vv-name="code"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0" md="6">
                      <label v-text="$t('discounts.type')"></label>

                      <v-select
                        :items="types"
                        v-model="discount.type"
                        outlined
                        hide-details
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" class="py-0" md="6">
                      <label v-text="$tc('discount')"></label>
                      <v-text-field
                        outlined
                        hide-details
                        dense
                        v-model="discount.discount"
                        data-vv-validate-on="blur"
                        v-validate="'required'"
                        :error-messages="
                          errors.collect('discount-form.discount')
                        "
                        data-vv-name="discount"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0" md="6">
                      <label v-text="$t('discounts.max_redemptions')"></label>
                      <v-text-field
                        outlined
                        hide-details
                        dense
                        v-model="discount.max_redemptions"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0" md="6">
                      <label v-text="$t('discounts.redeem_by')"></label>

                      <v-menu
                        ref="menu"
                        v-model="menu"
                        transition="scale-transition"
                        offset-y
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            hide-details
                            dense
                            class="filters"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :value="
                              discount.redeem_by
                                ? $d(
                                    new Date(discount.redeem_by),
                                    'date2digits'
                                  )
                                : ''
                            "
                            ><template v-slot:prepend-inner>
                              <!--Aqui va el v-icon -->
                              <v-icon
                                small
                                class="pt-0 pb-1"
                                style="margin-top: 5px"
                                :color="'primary'"
                                >$appointments</v-icon
                              >
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          first-day-of-week="1"
                          v-model="discount.redeem_by"
                          no-title
                          scrollable
                          color="primary"
                          @input="menu = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <div class="flex-grow-1"></div>

                  <v-btn
                    outlined
                    @click="
                      dialog = false;
                      files = [];
                    "
                    style="height: 25px; width: 100px"
                    elevation="0"
                    >{{ $t("cancel") }}</v-btn
                  >
                  <v-btn
                    type="submit"
                    style="
                      width: 100px !important;
                      color: #363533;
                      height: 25px;
                      padding-top: 10px !important;
                    "
                    elevation="0"
                  >
                    <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
                    {{ $t("save", { name: $t("") }) }}
                  </v-btn>
                </v-card-actions>
              </form>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
      >
        <template v-slot:item.discount="{ item }">
          {{
            $n(
              item.type == "percent" ? item.discount / 100 : item.discount,
              item.type
            )
          }}
        </template>
        <template v-slot:item.max_redemptions="{ item }">
          {{ item.max_redemptions || "-" }}
        </template>
        <template v-slot:item.redeem_by="{ item }">
          {{
            item.redeem_by ? $d(new Date(item.redeem_by), "date2digits") : "-"
          }}
        </template>
        <template v-slot:item.actions="{ item }">
          <ResumeDiscounts :discount="item" />
          <v-btn icon @click="update(item)" v-if="item.active">
            <v-icon small>$delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.active="{ item }">
          <v-chip
            :color="item.active ? 'primary' : 'gris1'"
            style="color: black"
          >
            {{
              item.active
                ? $t("discounts.states.active")
                : $t("discounts.states.inactive")
            }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

import { debounce } from "lodash";

export default {
  name: "Discounts",
  watch: {
    options: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
  components: {
    ResumeDiscounts: () => import("./ResumeDiscounts"),
  },
  data() {
    return {
      states: [
        { text: this.$t("all"), value: "all" },
        { text: this.$t("discounts.states.active"), value: "active" },
        { text: this.$t("discounts.states.inactive"), value: "inactive" },
      ],
      filters: {},
      headers: [
        {
          text: this.$t("discounts.code"),
          align: "center",
          sortable: false,
          value: "code",
        },
        {
          text: this.$tc("discount"),
          align: "center",
          sortable: false,
          value: "discount",
        },
        {
          text: this.$t("discounts.redeem_by"),
          align: "center",
          sortable: false,
          value: "redeem_by",
        },
        {
          text: this.$t("discounts.max_redemptions"),
          align: "center",
          sortable: false,
          value: "max_redemptions",
        },

        {
          text: this.$t("discounts.active"),
          align: "center",
          sortable: false,
          value: "active",
        },

        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      items: [],
      options: {},
      total: null,
      discount: {},
      types: [
        { text: "%", value: "percent" },
        { text: "€", value: "amount" },
      ],
      menu: false,
      dialog: false,
      dialogForm: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    openForm() {
      this.discount = {};
      this.dialogForm = true;
    },
    ...mapActions("discounts", [
      "getDiscounts",
      "addDiscount",
      "updateDiscount",
    ]),
    fetch() {
      this.getDiscounts({
        filters: this.filters,
        pagination: this.options,
      }).then((response) => {
        this.items = response.data;
        this.total = response.total;
      });
    },
    async validateForm(scope) {
      this.errors.clear();
      let result = await this.$validator.validateAll(scope);
      if (result) {
        this.addDiscount(this.discount).then((response) => {
          if (response.success) {
            this.$alert(this.$t("save_ok"));
            this.dialogForm = false;
            this.fetch();
          }
        });
      }
    },
    update(item) {
      this.$confirm(this.$t("discounts.deactivate", { code: item.code })).then(
        () => {
          this.updateDiscount(item.id).then(() => this.fetch());
        }
      );
    },
    searchFilter: debounce(function (val) {
      this.fetch();
    }, 1000),
  },
};
</script>